import type { GetTeaserObjectArgs } from "../../types/pulse";

import { getPulseTracker } from "..";
import { getTeaserObject } from "../helpers/getTeaserObject";
import { getTeaserTarget } from "../helpers/getTeaserTarget";

const trackTeaserClick = ({
  article,
  imageUrl,
  positionInFeed,
  curateContext,
  positionInBundle,
  context,
}: GetTeaserObjectArgs) => {
  const pulseTracker = getPulseTracker();
  const baseUrl = pulseTracker?.pulseTracker.builders.origin?.url || "";
  pulseTracker?.executePulseCommand("track", "engagementEvent", {
    action: "Click",
    object: getTeaserObject({
      article,
      imageUrl,
      positionInFeed,
      curateContext,
      positionInBundle,
      context,
    }),
    target: getTeaserTarget(article, baseUrl),
  });
};

export { trackTeaserClick };
