import type { GetTeaserObjectArgs } from "../../types/pulse";

import { getPulseTracker } from "..";
import { getTeaserObject } from "../helpers/getTeaserObject";
import { getTeaserTarget } from "../helpers/getTeaserTarget";

const trackTeaserImpression = ({
  article,
  imageUrl,
  positionInFeed,
  curateContext,
  positionInBundle,
  context,
}: GetTeaserObjectArgs) => {
  const pulseTracker = getPulseTracker();
  const baseUrl = pulseTracker?.pulseTracker.builders.origin?.url || "";

  if (baseUrl) {
    pulseTracker?.executePulseCommand("track", "trackerEvent", {
      object: getTeaserObject({
        article,
        imageUrl,
        positionInFeed,
        curateContext,
        positionInBundle,
        context,
      }),
      target: getTeaserTarget(article, baseUrl),
    });
  }
};

export { trackTeaserImpression };
